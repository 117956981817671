import { Box, Typography, createStyles } from "@material-ui/core";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import React, { ComponentProps } from "react";
import logo from "../../assets/logo.png";
const styles = () => {
	return createStyles({
		"@global": {
			"#root": {
				padding: "1px",
			},
			body: {
				backgroundSize: "cover",
				backgroundPosition: "bottom center",
			},
			"body, html": {
				padding: 0,
				margin: 0,
				height: "100%",
				width: "100%",
			},
		},
		container: {
			width: "750px",
			margin: "100px auto 40px auto",
			textAlign: "center",
			maxWidth: "calc(100% - 40px)",
		},
		logo: {
			width: "200px",
			display: "block",
			margin: "0 auto 25px auto",
		},
	});
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function SignIn(props: ComponentProps<"body"> & WithStyles): JSX.Element {
	const { classes, children } = props;
	return (
		<Box className={classes.container}>
			<img src={logo} alt="logo" className={classes.logo} />
			<Box marginBottom={6}><Typography variant="h6"><strong>Специализиран софтуер за управление, SEO оптимизация и
				създаване на дигитално съдържание за онлайн търговия в Амазон</strong></Typography></Box>
			<Box sx={{ width: "400px", maxWidth: "100%", marginLeft: "auto", marginRight: "auto", mb: 15 }}>
				{children}
			</Box>
			<Typography align="left" variant="body1">
				Софтуерът е закупен по проект BG06RDNP001-19.723-0002-C01 по процедура за
				предоставяне на безвъзмездна финансова помощ № BG06RDNP001-19.723 МИГ
				Луковит-Роман: мярка 19-6.4 "Инвестиции в подкрепа на неземеделски дейности".
			</Typography>
		</Box >
	);
}

export default withStyles(styles)(SignIn);
